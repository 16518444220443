<div class="new-pop">
  <h1>{{language.PREORDER}}</h1>
  <a href="javascript:void(0);" (click)="onNoClick()" class="pop-cls">
    <svg id="Capa_1" enable-background="new 0 0 413.348 413.348" viewBox="0 0 413.348 413.348"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="m413.348 24.354-24.354-24.354-182.32 182.32-182.32-182.32-24.354 24.354 182.32 182.32-182.32 182.32 24.354 24.354 182.32-182.32 182.32 182.32 24.354-24.354-182.32-182.32z" />
    </svg>
  </a>
  <div class="prdc-pic">
    <div class="row">
      <div class="col-md-12">
        <div class="prdc-pic-drc">
          <div class="mat-img"><img [lazyLoad]="data.business.logo"></div>
          <div class="prdc-drc">
            <p>{{data.business.name}}</p>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div class="Prd-bdy">
    <ngx-simplebar [options]="options" >
      <div #scrollMe>
      <div class="pre-box1">
        <label>1. {{language.CHOOSE_ANY_MENU}}</label>
        <div class="wk-days" *ngFor="let menu of data.menu" (click)="changeMenu(menu)">
          <h5>{{menu.name}}<span class="mkup"></span> <span *ngIf="menu.active" matBadge="&#10004;"
              class="mat-badge-custom"></span></h5>
          <ul>
            <li *ngFor="let days of menu.days">{{displayVal(days)}}</li>
          </ul> 
        </div>
        <div class="wk-days" *ngIf="!menuStatus">
          <p>{{language.NOT_ACTIVE_MENU}}</p>
        </div>
      </div>
      <div class="pre-box1">
        <label>2. {{language.SELECT_DATE_TIME}}</label>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <input matInput autocomplete="off" [matDatepickerFilter]="dateFilter" [min]="minDate" [max]="maxDate"
                [matDatepicker]="picker" (dateChange)="addEvent('change', $event)"
                placeholder="{{language.SELECT_PREORDER_DATE}}" (click)="picker.open()" (keydown)="false">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker [dateClass]="dateClass" #picker></mat-datepicker>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <select [(ngModel)]="data.preorder.preorderTime" required #b_time>
                <option value="">{{language.SELECT_PREORDER_TIME}}</option>
                <option *ngFor="let opt of menuTime" [value]="opt">{{opt}}</option>
              </select>
            </div>
          </div>
        </div>

      </div>
    </div>
    </ngx-simplebar>
  </div>
  <button (click)="onSubmitClick()" class="cmn-button" cdkFocusInitial>{{language.SUBMIT}}</button>
</div>
