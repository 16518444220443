<div class="new-pop">
  <div class="product-optn">
    <h1>{{dish.name}}<b *ngIf="dish.price > 0">(<span
          *ngIf="orderType == '1' && dish.offerprice > 0">{{businessDetails.currency_symbol}}{{dish.delprice}}</span><span
          *ngIf="orderType == '2' && dish.pofferprice > 0">{{businessDetails.currency_symbol}}{{dish.pickprice}}</span>
        {{businessDetails.currency_symbol}}{{dish.price}})</b></h1>
    <a href="javascript:void(0);" (click)="onNoClick()" class="pop-cls">
      <svg id="Capa_1" enable-background="new 0 0 413.348 413.348" viewBox="0 0 413.348 413.348"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="m413.348 24.354-24.354-24.354-182.32 182.32-182.32-182.32-24.354 24.354 182.32 182.32-182.32 182.32 24.354 24.354 182.32-182.32 182.32 182.32 24.354-24.354-182.32-182.32z" />
      </svg>
    </a>
    <!-- <share-buttons *ngIf="businessDetails.productsharestatus == 1" [theme]="'circles-dark'"
      [include]="['facebook','twitter','whatsapp']" [show]="3" [size]="-4" [url]="dishShare.url"
      [description]="dishShare.description" [autoSetMeta]="false"></share-buttons> -->
      <ngx-simplebar [options]="options">
      <div class="prdc-pic">
      <div class="row">
        <div class="col-md-12">
          <div class="prdc-pic-drc">
            <div class="img-group" *ngIf="dish.is_img == 1 || dish.is_img1 == 1 || dish.is_img2 == 1">
              <div class="item-pic" *ngIf="dish.is_img == 1">
                <a data-fancybox="dish" href="{{dish.img}}">
                  <img [lazyLoad]="dish.img" />
                </a>
              </div>
              <div class="item-pic" *ngIf="dish.is_img1 == 1">
                <a data-fancybox="dish" href="{{dish.img1}}">
                  <img [lazyLoad]="dish.img1" />
                </a>
              </div>
              <div class="item-pic" *ngIf="dish.is_img2 == 1">
                <a data-fancybox="dish" href="{{dish.img2}}">
                  <img [lazyLoad]="dish.img2" />
                </a>
              </div>
            </div>
            <div [ngClass]="{'prdc-drc' : dish.is_img == 1 || dish.is_img1 == 1 || dish.is_img2 == 1}">
              <p>{{dish.description}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="option-item-bdy">

        <div class="option-item" *ngIf="dish.ingredientsarray.length > 0">
          <div class="row">
            <div class="col-md-12">
              <h4>{{language.INGREDIENTS}}</h4>
              <p>{{language.UNCHECK_OPTIONS}}</p>
              <div class="option-itm-grp">
                <ul>
                  <li *ngFor="let ingredients of dish.ingredientsarray; index as ingreindex">
                    <div class="icheck-material-pink">
                      <input type="checkbox" [(ngModel)]="ingredients.status" id="ingre_{{ingreindex}}">
                      <label for="ingre_{{ingreindex}}">{{ingredients.name}}</label>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>


        <div *ngFor="let sets of dish.sets; index as setindex">
          <div *ngFor="let option of sets.options; index as optionindex">

            <div class="option-item" *ngIf="option.show">
              <div class="row">
                <div class="col-md-12">
                  <h4>{{option.name}}<span *ngIf="option.required == true">*</span></h4>
                  <p *ngIf="option.type == 1">({{language.MIN}}: {{option.min}} & {{language.MAX}}: {{option.max}} )</p>
                  <div class="option-itm-grp">
                    <ul>
                      <li *ngFor="let choice of option.choice; index as choiceindex">
                        <div class="icheck-material-pink" *ngIf="option.type == 1">
                          <input type="checkbox" id="choice_{{optionindex}}_{{choice.id}}"
                            (click)="checkProductOption(setindex,optionindex,choiceindex)" [(ngModel)]="choice.checked">
                          <label for="choice_{{optionindex}}_{{choice.id}}">{{choice.name}} <span
                              *ngIf="choice.price > 0">{{businessDetails.currency_symbol}}{{choice.price}}</span>
                          </label>
                        </div>

                        <div class="icheck-material-pink" *ngIf="option.type == 0">
                          <input type="radio" id="choice{{choice.id}}" name="icons{{option.id}}"
                            (click)="checkProductOption(setindex,optionindex,choiceindex)"
                            [checked]="choice.checked === true">
                          <label for="choice{{choice.id}}">{{choice.name}} <span
                              *ngIf="choice.price > 0">{{businessDetails.currency_symbol}}{{choice.price}}</span>
                          </label>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="option-cmnt">
          <textarea autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"
            (keypress)="omit_special_char($event)" placeholder="{{language.SPECIAL_COMMENT}}" [(ngModel)]="dish.notes"
            [ngModelOptions]="{updateOn: 'blur'}"></textarea>
        </div>

    </div>
</ngx-simplebar>
    <div class="pop-ftr">
      <button type="button" class="ad-btn">
        {{language.ORDER_TEMPLATE_TOTAL}} {{businessDetails.currency_symbol}}{{dishTotal}}
        <span *ngIf="indexcart.EDITPRODUCTCART != 1" (click)="addToCart()">{{language.ADD}}</span>
        <span *ngIf="indexcart.EDITPRODUCTCART == 1" (click)="editTocart()">{{language.UPDATE}}</span>
      </button>
    </div>
  </div>
